import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  FormControl,
  Divider,
  Box,
  CardContent,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import FeedIcon from '@mui/icons-material/Feed';

import {
  FieldPhone,
  FieldEmail,
  FieldText,
  FieldtextArea,
  FieldNumber,
  FieldList,
  FieldFile,
  FieldDateTime,
  FieldListImputIncrement,
} from './fields';

function CreateForms({
  fields,
  getFields,
  getEnabledForm,
}) {
  const [tiposCampos] = useState([
    'grupo',
    'text',
    'textarea',
    'list',
    'file',
    'phone',
    'email',
    'number',
    'date',
    'time',
    'rangeDate',
    'rangeTime',
    'listInput',
  ]);

  const [formulario, setFormulario] = useState({});

  useEffect(() => {
    setFormulario(JSON.parse(JSON.stringify(fields)));
  }, [fields]);

  const validateForm = () => {
    let enable = true;
    for (const g in formulario.groups) {
      for (const f in formulario.groups[g].fields) {
        if (formulario.groups[g].fields[f].kind !== 'list' && formulario.groups[g].fields[f].required && (formulario.groups[g].fields[f].value === '' || formulario.groups[g].fields[f].value === undefined || formulario.groups[g].fields[f].value === null)) {
          enable = false;
          break;
        }

        if (formulario.groups[g].fields[f].kind === 'list' && (formulario.groups[g].fields[f].required && formulario.groups[g].fields[f].value.length <= 0 || formulario.groups[g].fields[f].value === null)) {
          enable = false;
          break;
        }
      }
      if (!enable) break;
    }
    getEnabledForm(enable);
  };

  useEffect(() => {
    getFields(formulario);
    validateForm();
  }, [formulario]);

  const updateFields = (index, newValue, indexGroup) => {
    const newGroups = formulario.groups;
    newGroups[indexGroup].fields[index].value = newValue;
    setFormulario({ ...formulario, groups: newGroups });
  };

  const handleUpload = (index, newValue, indexGroup) => {
    const newGroups = formulario.groups;
    const file = newValue.target.files[0];
    newGroups[indexGroup].fields[index].value = newValue;
    newGroups[indexGroup].fields[index].file = file;
    setFormulario({ ...formulario, groups: newGroups });
  };

  const buildFields = (fieldGroup, indexGroup) => {
    const fieldsLocal = fieldGroup.map((field, indexField) => (

      <Box key={field.id}>
        {/* CAMPO TEXTO */}
        {field.kind === tiposCampos[1] && (
          <FieldText
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* CAMPO AREA DE TEXTO */}
        {field.kind === tiposCampos[2] && (
          <FieldtextArea
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* CAMPO DE LISTA */}
        {field.kind === tiposCampos[3] && (
          <FieldList
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* CAMPO FILE */}
        {field.kind === tiposCampos[4] && (
          <FieldFile
            field={field}
            onChange={handleUpload}
            indexField={indexField}
            indexGroup={indexGroup}
          />

        )}
        {/* CAMPO phone */}
        {field.kind === tiposCampos[5] && (
          <FieldPhone
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* CAMPO email */}
        {field.kind === tiposCampos[6] && (
          <FieldEmail
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* CAMPO NUMERO */}
        {field.kind === tiposCampos[7] && (
          <FieldNumber
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* DATE */}
        {field.kind === tiposCampos[8] && (
          <FieldDateTime
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}
        {/* FIEDS LIST IMPUT */}
        {field.kind === tiposCampos[12] && (
          <FieldListImputIncrement
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
          />
        )}

      </Box>
    ));

    return fieldsLocal;
  };

  return (

    formulario.groups ? formulario.groups.map((itemGroup, indexGroup) => (
      <>
        <Box sx={{
          pl: 0, pr: 0, pt: 2,
        }}
        >
          <Typography
            sx={{
              pt: 0, pb: 0, pl: 2, pr: 2, mb: 0,
            }}
            variant="h6"
            component="p"
          >
            <FeedIcon sx={{ mb: -0.8 }} />
            {' '}
            {itemGroup.title}
          </Typography>
          <Typography variant="p" component="p">
            {/** DATOS DINAMICOS */}
            <Box sx={{ mb: 0, mt: 0 }}>
              <FormControl fullWidth size="small">
                <CardContent sx={{
                  m: 0, pl: 2, pr: 2, pb: 0, pt: 0,
                }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {buildFields(itemGroup.fields, indexGroup)}
                  </Typography>
                </CardContent>
              </FormControl>
            </Box>
          </Typography>
        </Box>
        <Divider />
      </>
    )) : null

  );
}

CreateForms.propTypes = {
  fields: PropTypes.objectOf.isRequired,
  getFields: PropTypes.func.isRequired,
  getEnabledForm: PropTypes.func.isRequired,
};

export default CreateForms;
