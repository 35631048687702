import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Divider,
  Box,
  ButtonGroup,
  CardContent,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { PropTypes } from 'prop-types';
import { comentsService } from '../../../../infrastructure/services/coments';
import { comentsActions } from '../../../../application/actions/coments';
import './index.css';

function ComentsView({
  show,
  handlerShow,
  cardSelected,
}) {
  const dispatch = useDispatch();

  const {
    createComents,
    getComents,
    updateComents,
    deleteComents,
  } = useSelector((store) => store.bazooit.coments);
  const {
    id: idSesion,
  } = useSelector((store) => store.bazooit.session);
  const [comment, setComment] = useState('');
  const [myComents, setMyComents] = useState([]);
  const [editComent, setEditComent] = useState(undefined);

  const handlreGetComents = () => {
    dispatch(comentsActions.createComentsActions({ result: false, connection: false }));
    dispatch(comentsActions.updateComentsActions({ result: false, connection: false }));
    dispatch(comentsService.getComents({ cardId: cardSelected.id }));
  };

  useEffect(() => {
    if (cardSelected.id) {
      handlreGetComents();
    }
  }, [cardSelected]);

  const capitalizarPrimeraLetra = (str) => {
    if (str === null || str === '') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handlerSendComent = () => {
    const message = { cardId: cardSelected.id, comment };
    dispatch(comentsService.createComents({ message }));
  };

  const handlerDeleteComent = (commentId) => {
    const message = { cardId: cardSelected.id, commentId };
    dispatch(comentsService.deletComents({ message }));
  };

  const handlerUpdateComent = () => {
    const commentId = editComent.id;
    const message = {
      comment,
      cardId: cardSelected.id,
      commentId,
    };
    dispatch(comentsService.updateComents({ message }));
  };

  useEffect(() => {
    if (createComents.result) {
      setComment('');
      handlreGetComents();
    }
  }, [createComents]);

  useEffect(() => {
    if (deleteComents.result) {
      handlreGetComents();
    }
  }, [deleteComents]);

  useEffect(() => {
    if (getComents.coments.length > 0) {
      setMyComents(getComents.coments);
      // handlreGetComents();
    }
  }, [getComents.coments]);

  const validateUser = (flagIn) => {
    const flag = idSesion;
    let validate = false;
    if (flag === flagIn) {
      validate = true;
    }
    return validate;
  };

  const saveComentEdit = (commentIn) => {
    setEditComent(commentIn);
    if (commentIn !== undefined) {
      setComment(commentIn.comment);
    } else {
      setComment('');
    }
  };

  useEffect(() => {
    if (updateComents.result) {
      handlreGetComents();
      saveComentEdit(undefined);
    }
  }, [updateComents]);

  const handlreExit = () => {
    dispatch(comentsActions.getComentsActions({ coments: [] }));
    setMyComents([]);
    handlerShow(undefined, 'coments');
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={show}
      onClose={() => handlreExit()}
      fullWidth="sm"
      maxWidth="sm"
    >
      <DialogTitle sx={{ lineHeight: 1.2, backgroundColor: '#bee6e7' }}>
        <Typography sx={{ pb: 0, mb: 0, color: '#618c8c' }} variant="h5" component="p">
          {getComents.result ? cardSelected.visibleFieldsValues[0] : <Skeleton variant="rectangular" width={300} height={30} />}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          pl: 0, pr: 0, backgroundColor: '#bee6e7', rotate: '180deg', direction: 'rtl', boxShadow: 'inset  0px 0px 27px -10px rgba(0,0,0,0.1)',
        }}
      >
        <Box sx={{ pl: 3, pr: 3, rotate: '180deg' }}>
          <CardContent sx={{
            m: 0, p: 0, mt: -3, overflow: 'hidden',
          }}
          >
            {myComents && myComents.length === 0 ? (
              <Box
                sx={{
                  display: 'flex-inline',
                  mb: 2,
                  direction: 'rtl',
                }}
              >
                <Paper sx={{
                  p: 1.5,
                  borderRadius: 2,
                  borderBottomRightRadius: 0,
                  width: 'fit-content',
                  backgroundColor: '#618c8c',
                  direction: 'ltr',
                }}
                >
                  <Typography sx={{
                    fontSize: 15, color: '#FFF', fontWeight: 100,
                  }}
                  >
                    No existen comentarios
                  </Typography>
                </Paper>

              </Box>
            ) : (
              <>
                {myComents.toReversed().map((item, index) => (

                  <Box
                    sx={{
                      display: 'flex-inline',
                      mb: 2,
                      direction: validateUser(item.modifiedBy) ? 'rtl' : 'ltr',
                      opacity: editComent !== undefined ? (editComent.id === item.id ? 1 : 0.3) : 1,
                    }}
                  >
                    <Typography sx={{
                      fontSize: 12, color: '#6c6c6c', fontWeight: 100, mb: 0.5,
                    }}
                    >
                      {item.modifiedByName}
                      {'   -   '}
                      {moment(item.modifiedAt).format('YYYY-MM-DD HH:MM')}
                    </Typography>

                    <Tooltip
                      TransitionComponent={Zoom}
                      placement="top-end"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#FFF',
                            '& .MuiTooltip-arrow': {
                              color: 'common.black',
                            },
                          },
                        },
                      }}
                      title={validateUser(item.modifiedBy) && editComent === undefined && (index + 1) === myComents.length && (
                        <ButtonGroup>
                          <IconButton size="small" onClick={() => saveComentEdit(item)} aria-label="share">
                            <EditIcon fontSize="10px" />
                          </IconButton>

                          <IconButton size="small" onClick={() => handlerDeleteComent(item.id)} aria-label="share">
                            <HighlightOffIcon fontSize="10px" />
                          </IconButton>
                        </ButtonGroup>
                      )}
                    >
                      <Paper sx={{
                        p: 1.5,
                        borderRadius: 2,
                        borderTopLeftRadius: validateUser(item.modifiedBy) ? 10 : 0,
                        borderTopRightRadius: validateUser(item.modifiedBy) ? 0 : 10,
                        width: 'fit-content',
                        backgroundColor: validateUser(item.modifiedBy) ? (editComent !== undefined && editComent.id === item.id ? '#ff9600' : '#618c8c') : '#FFFFFF',
                      }}
                      >

                        <Typography sx={{
                          fontSize: 15, color: validateUser(item.modifiedBy) ? '#FFF' : '#6c6c6c', fontWeight: 100,
                        }}
                        >
                          {capitalizarPrimeraLetra(item.comment)}
                        </Typography>

                        { }

                      </Paper>
                    </Tooltip>
                  </Box>
                ))}
              </>
            )}
          </CardContent>

        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: '#bee6e7' }}>

        <FormControl sx={{ m: 1 }} fullWidth>
          <OutlinedInput
            sx={{
              backgroundColor: '#FFF',
              borderRadius: 2,
              boxShadow: '0px 0px 27px -10px rgba(0,0,0,0.2)',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFF',
              },
              '&:hover > .MuiOutlinedInput-notchedOutline': {
                borderColor: '#618c8c',
              },
              '&:select > .MuiOutlinedInput-notchedOutline': {
                borderColor: '#618c8c',
              },
            }}
            type="text"
            placeholder={editComent !== undefined ? 'Edita este mensaje...' : 'Escribe un mensaje nuevo'}
            disabled={createComents.connection}
            endAdornment={(
              <InputAdornment position="end">
                {editComent !== undefined ? (
                  <>
                    <IconButton
                      disabled={updateComents.connection}
                      onClick={() => { saveComentEdit(undefined); }}
                      edge="end"
                    >
                      <CloseIcon sx={{ color: '#618c8c' }} />
                    </IconButton>
                    <IconButton
                      disabled={updateComents.connection || comment === editComent.comment}
                      onClick={() => { handlerUpdateComent(); }}
                      edge="end"
                    >
                      <CheckIcon sx={{ color: '#618c8c' }} />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    disabled={createComents.connection || comment === ''}
                    onClick={() => { handlerSendComent(); }}
                    edge="end"
                  >
                    <SendIcon sx={{ color: '#618c8c' }} />
                  </IconButton>
                )}
              </InputAdornment>
            )}
            value={comment}
            onChange={(e) => { setComment(e.target.value); }}
          />

        </FormControl>
      </DialogActions>
    </Dialog>

  );
}

ComentsView.propTypes = {
  show: PropTypes.bool.isRequired,
  handlerShow: PropTypes.number.isRequired,
  cardSelected: PropTypes.number.isRequired,
};

export default ComentsView;
