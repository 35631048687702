import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  Divider,
  Box,
  ButtonGroup,
  CardContent,
  Alert,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogActions from '@mui/material/DialogActions';
import { cardsActionsSaga } from '../../../../infrastructure/services/cards';
import CreateForms from '../../organismos/forms';
import FieldDowload from '../fieldDowload';

function ArtefactoMostrar({
  artefactoMostar,
  columIdMostar,
  artefactoMostarData,
  handleCloseMostrar,
  idWorkflowSelected,
}) {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(true);
  const [newFields, setNewFields] = useState(null);
  const [enabledForm, setEnabledForm] = useState(null);
  useEffect(() => {
    if (artefactoMostarData) {
      const query = `${artefactoMostarData}/?columnId=${columIdMostar}`;
      dispatch(cardsActionsSaga.activeCardsGetDetail({ query }));
    }
  }, [artefactoMostarData]);

  const { cardDetail, result, connection } = useSelector((store) => store.bazooit.cards.cardDetail);
  const { cardHasBeenUpdated } = useSelector((store) => store.bazooit.cards);

  const capitalizarPrimeraLetra = (str) => {
    if (str === null || str === '') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getFields = (get) => {
    setNewFields(get);
  };

  const getEnabledForm = (flag) => {
    setEnabledForm(flag);
  };
  const buscarDatos = () => {
    const buildetail = cardDetail != null && cardDetail.groups.map((group) => (
      <Box key={group}>
        <Typography sx={{ pl: 1 }} variant="h6" color="text.secondary">
          <strong>{group.title}</strong>
        </Typography>
        {
          group.fields.map((field) => (
            <Typography key={field.id} sx={{ pl: 1 }} variant="body2" color="text.secondary">
              <strong>{field.label}</strong>
              :
              {field.kind === 'file' && <FieldDowload value={field.value} url={field.url} />}
              {field.kind === 'list' && field.options.filter((item) => item.id === field.value)[0].label}
              {field.kind !== 'file' && field.kind !== 'list' && field.value}
            </Typography>
          ))
        }
        <br />
      </Box>
    ));

    return buildetail;
  };

  const handlerChabgeVisibility = () => {
    const temp = !visibility;
    setVisibility(temp);
  };

  const handlerActualizar = () => {
    let data = {};
    for (const a in newFields.groups) {
      for (const b in newFields.groups[a].fields) {
        if (newFields.groups[a].fields[b].kind !== 'file') {
          data = { ...data, [newFields.groups[a].fields[b].id]: newFields.groups[a].fields[b].value };
        } else {
          data = { ...data, [newFields.groups[a].fields[b].id]: newFields.groups[a].fields[b].file };
        }
      }
    }
    dispatch(cardsActionsSaga.activeCardsUpdateData(
      {
        cards: {
          workflowId: idWorkflowSelected,
          columnId: columIdMostar,
          ...data,
        },
        idCard: artefactoMostarData,
      },
    ));
    handleCloseMostrar();
  };

  const handlerCheckEdit = () => {
    dispatch(cardsActionsSaga.activeCardHasBeenUpdated({
      cardId: artefactoMostarData,
      updatedAt: newFields.updatedAt,
    }));
    // handleCloseMostrar();
  };
  useEffect(() => {
    if (cardHasBeenUpdated.result) { handlerActualizar(); }
  }, [cardHasBeenUpdated]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={artefactoMostar}
      onClose={handleCloseMostrar}
      fullWidth="sm"
      maxWidth="sm"
    >
      <DialogTitle sx={{ lineHeight: 1.2 }}>
        {!cardHasBeenUpdated.checking && (
          <Typography sx={{ pb: 0, mb: 0 }} variant="h5" component="p">
            {result ? capitalizarPrimeraLetra(cardDetail.title) : <Skeleton variant="rectangular" width={300} height={30} />}
          </Typography>
        )}
        {
          cardHasBeenUpdated.checking
          && !cardHasBeenUpdated.result
          && !cardHasBeenUpdated.connection && (
            <Typography sx={{ pb: 0, mb: 0 }} variant="h5" component="p">
              {result ? 'Confirmar cambios' : <Skeleton variant="rectangular" width={300} height={30} />}
            </Typography>
          )
        }
      </DialogTitle>
      {!cardHasBeenUpdated.checking && !cardHasBeenUpdated.connection && (
        <IconButton
          aria-label="close"
          onClick={handlerChabgeVisibility}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {visibility ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
      )}
      <Divider />
      {' '}

      {cardHasBeenUpdated.checking && !cardHasBeenUpdated.result
        ? (
          <DialogContent sx={{ pl: 0, pr: 0 }}>
            <Box sx={{ pl: 4, pr: 4 }}>
              <Alert severity="error">{cardHasBeenUpdated.message}</Alert>
            </Box>
          </DialogContent>
        ) : (
          <>
            {
              result && !visibility && (
              <CreateForms
                fields={cardDetail}
                getFields={getFields}
                getEnabledForm={getEnabledForm}
              />
              )
            }
            {result && connection && visibility && (
              <DialogContent sx={{ pl: 0, pr: 0 }}>
                <Box sx={{ pl: 3, pr: 3 }}>
                  <Typography>
                    <Box>
                      <FormControl>
                        <CardContent sx={{ m: 0, p: 0, mt: 1 }}>
                          <Typography>
                            {buscarDatos(cardDetail)}
                          </Typography>
                        </CardContent>
                      </FormControl>
                    </Box>
                  </Typography>
                </Box>
              </DialogContent>
            )}
          </>
        )}
      <DialogActions>
        <FormControl fullWidth>
          {cardHasBeenUpdated.checking && !cardHasBeenUpdated.result ? (
            <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
              <Button onClick={(e) => { handlerActualizar(e); }}>
                Si, Confirmar
              </Button>
              <Button onClick={(e) => { handleCloseMostrar(e); }}>
                Descartar Cambios
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
              {!visibility
                && (
                  <Button disabled={!enabledForm} onClick={() => handlerCheckEdit()}>
                    Actualizar
                  </Button>
                )}
              <Button onClick={(e) => { handleCloseMostrar(e); }}>
                Salir
              </Button>
            </ButtonGroup>
          )}

        </FormControl>
      </DialogActions>
    </Dialog>

  );
}

export default ArtefactoMostrar;
